import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/index'
import store from './store/index'
import axios from 'axios'
import  './assets/css/app.css'
// import 'bootstrap/dist/css/bootstrap.css';
// import './assets/css/app.css';





axios.defaults.baseURL = 'https://payrollium.b2camp.id';
console.log(localStorage.getItem("token"))
if(localStorage.getItem("token")!== undefined) 
  {
    let auth=localStorage.getItem("token")
    store.state.token = auth
    const token = store.state.token;
    axios.interceptors.request.use(
      (config) => {
        
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.use(store);
app.use(router);
app.mount('#app');