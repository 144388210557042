<template>
    <router-view/>
    <div class="w-screen h-screen flex" style="margin-top: -65px;">
        <!--side Bar-->
       <div class="w-[400px] h-full bg-gray-200 text-white">
         <div class="h-[50px] bg-gray-900 flex justify-start  items-center ">
          <div class="px-[20px]">
            <h2>Welcome</h2>
            <!-- <h3 class="font-bold text-xl">Dasboard</h3> -->   
          </div>
         </div>
         <div class="h-[calc(100vh-50px)] bg-gray-800 py-[20px]">
          <div class="flex felx-col justify-between h-full px-[20px] space-y-[10px]">       
          <div class=" flex flex-col justify-between space-y-[10px]">
            <div class="h-full">
              <router-link to="/home" class="inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium rounded-md border-gray-200 hover:bg-gray-200 hover:text-gray-800  transition duration-400 ease-in-out">
                <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path>
                </svg>
                Home
              </router-link>
              <router-link to="#" class="inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium rounded-md border-gray-200 hover:bg-gray-300  hover:text-gray-800 transition duration-400 ease-in-out">
                <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path>
                </svg>
                Profile
              </router-link>
              <router-link to="#" class="inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium rounded-md border-gray-200 hover:bg-gray-300  hover:text-gray-800 transition duration-400 ease-in-out">
                <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path>
                </svg>
                list
              </router-link>
            
            </div>
            <div class="h-[50px]">
              <div>
                <router-link to="list" class="inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium rounded-md border-gray-200 hover:bg-gray-300 hover:text-gray-800  transition duration-400 ease-in-out">
                  <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path>                 
                  </svg>
                  <a @click="confirmLogout" class="nav-link " aria-current="page" >Logout</a>
                </router-link>
              </div>
            </div>
          </div>        
        </div>    
         </div>
       </div>
       <div class="w-full h-full bg-gray-400">
        <div class="h-[50px] bg-gray-100 flex items-center shadow-sm px-[20px] w-full py-[10px] z-10 border-b ">
          <!--Hamburger Menu-->
          <div class="cursor-pointer w-[30px]" @click="toggleSideBar">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class=" w-[25px] h-[25px]">
              <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              
            </svg>
          </div>
           <!-- Search bar-->
           <div class="w-[calc(100%-30px)] flex">
            <div class="w-[calc(100%-200px)] flex justify-center ">
              <!-- Search bar -->
              <form class="flex items-center w-[500px]">
                <label for="voice-search" class="sr-only">Search</label>
                <div class="relative w-full">
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" class="w-5 h-auto text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <input type="text" id="voice-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search..." required>
                  <router-link to="#" class="flex absolute inset-y-0 right-0 items-center pr-3">
                    <svg aria-hidden="true" class="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z" clip-rule="evenodd"></path>
                    </svg>
                  </router-link>
                </div>
              </form>
            </div>
            <!--Pasang Foto-->
  
  
            <div v-show="showDropDown" class="absolute right-[10px] z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="py-1 text-left" role="none">
                  <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                  <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</a>
                  <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Support</a>
                  <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">License</a>
                  <form method="POST" action="#" role="none">
                    <button type="submit" class="text-gray-700 block w-full px-4 py-2 text-left text-sm" role="menuitem" tabindex="-1" id="menu-item-3">Sign out</button>
                  </form>
                </div>
              </div>
         </div>
         </div>
        <div class="h-[calc(100vh-50px)] bg-white">
          <a href="/listOrganizations/add">add</a>
    <table>
        <thead>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>Image</th>
            <th>contact</th>
            <th>address</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="org in organizations" :key="org.id">
            <td>{{ org.id }}</td>
            <td>{{ org.name }}</td>
            <td>
              <img :src="org.imageUrl" style="width: 50px"  />
            </td>
            <td>{{ org.contact }}</td>
            <td>{{ org.address }}</td>
            <td>
              <button class="button" @click="routeToEditOrganization(org.id)">edited</button>|
              <button class="button" @click="deleteOrganization(org.id)">deleted</button>
            </td>
          </tr>
        </tbody>
     </table>
        </div>
       </div>
        <!--Main-->
      </div>
    
  </template>
  <script>
    import { mapActions } from "vuex";
    
    export default {
      name: "Nav",
      data() {
        return {
          user: {
            name: "",
          },
          organizations: [],
        fields: [
          { key: "id", label: "ID" },
          { key: "name", label: "Name" },
          { key: "imageUrl", label: "Image" },
          { key: "contact", label: "Contact" },
          { key: "address", label: "Address" },
          { key: "actions", label: "Actions" },        
        ],  
        };
      },
      mounted() {
        
        this.fetchOrganizations();
      },
      methods: {
        ...mapActions(["logout"]),
        confirmLogout() {
          if (confirm("Anda Yakin?")) {
            this.handleLogout();
          }
        },
        handleLogout() {
          this.logout();
          this.$router.push("/login")
          .then(() => {
            setTimeout(() => {          
                return alert("Berhasil Logout.");
              }, 300); // Delay of 100ms
          })
        },
        getUserData() {
          const token = this.$store.state.token;
          this.$axios
            .get("/organizations", {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((profileResponse) => {
              const users = profileResponse.data;
              const userId = users[0]?._id;
    
              if (!userId) {
                console.error("User ID not found in the array.");
                return;
              }
    
              this.$axios
                .get(`/organizations/${userId}`, {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .then((res) => {
                  this.user = res.data; // Assign fetched user data to 'user' object
                })
                .catch((error) => {
                  console.error("Error fetching user data: ", error);
                });
            })
            .catch((error) => {
              if (error.response && error.response.status === 401) {
                this.errorMessage = "Unauthorized access - please log in again.";
                this.logout();
              } else {
                console.error(
                  "Profile fetch failed:",
                  error.response ? error.response.data.message : error.message
                );
              }
            });
        },
        fetchOrganizations() {
        
        this.$axios.get('/organizations')
          .then(response => {
            this.organizations = response.data.map(org => ({
              id: org._id,
              name: org.name,
              imageUrl: org.imageUrl,
              contact: org.contact,
              address: org.address,
            }))
            
          ;
          })
          .catch(error => {
            alert('Error fetching organizations: ' + error.message);
          });
      },
      routeToAddOrganization() {
        this.$router.push({ name: 'AddOrganization' });
      },
      routeToEditOrganization(id) {
        this.$router.push({ name: 'EditOrganization', params: { id } });
      },
      deleteOrganization(id) {
        this.$axios.delete(`/organizations/${id}`)
          .then(() => {
            this.fetchOrganizations();
          })
          .catch(error => {
            alert('Error deleting organization: ' + error.message);
          });
      },
      }
    };
    </script>