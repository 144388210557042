<template>
  
<select @change="handlechange" style="width: 200px;">
    <option v-for="org in organizations" :value="org.name"  :key="org.id"  >
        {{ org.name }}
    </option>
</select>

    <table>
        <thead>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>Image</th>
            <th>contact</th>
            <th>address</th>
            <th>Action</th>
          </tr>

        </thead>
        <tbody>
            <tr v-for="org in filterArray" :key="org.id">
            <td>{{ org.id }}</td>
            <td>{{ org.name }}</td>
            <td>
              <img :src="org.imageUrl" style="width: 50px"  />
            </td>
            <td>{{ org.contact }}</td>
            <td>{{ org.address }}</td>
            <td>
              <button class="button" @click="routeToEditOrganization(org.id)">edited</button>|
              <button class="button" @click="deleteOrganization(org.id)">deleted</button>
            </td>
          </tr>  
        </tbody>
    </table>
</template>

<script>
 export default {
    data() {
      return {
        organizations: [],
        filterArray:[],
        optionvalue: "",
        fields: [
          { key: "id", label: "ID" },
          { key: "name", label: "Name" },
          { key: "imageUrl", label: "Image" },
          { key: "contact", label: "Contact" },
          { key: "address", label: "Address" },
          { key: "actions", label: "Actions" },        
        ],      
      };
    },

    mounted() {
      this.fetchOrganizations();
    },
    methods: {
      handlechange(event){
       this.optionvalue=event.target.value
       console.log(this.optionvalue)
       this.filterdata()
      },

      filterdata(){
        this.filterArray=[...this.organizations].filter(item=>item.name.toString()===this.optionvalue.toString())
        console.log(this.filterArray)

      },


      fetchOrganizations() {

        
        this.$axios.get('/organizations')
          .then(response => {
            this.organizations = response.data.map(org => ({
              id: org._id,
              name: org.name,
              imageUrl: org.imageUrl,
              contact: org.contact,
              address: org.address,
            }));
            console.log("organizations:", this.organizations);
            this.filterArray=this.organizations
            
          ;
          })
          .catch(error => {
            alert('Error fetching organizations: ' + error.message);
          });
      },
      routeToAddOrganization() {
        this.$router.push({ name: 'AddOrganization' });
      },
      routeToEditOrganization(id) {
        this.$router.push({ name: 'EditOrganization', params: { id } });
      },
      deleteOrganization(id) {
        this.$axios.delete(`/organizations/${id}`)
          .then(() => {
            this.fetchOrganizations();
          })
          .catch(error => {
            alert('Error deleting organization: ' + error.message);
          });
      },
    },
  };





      
    
</script>
