<template>
  <div class="table1">
    <div class="tabel_header">
      <p>Data User</p>
      <div>
        <input class="p" placeholder="User"/>
        <button class="add_User">+ Add User</button>
        <a href="/dasbord">Kembali</a>
      </div>
    </div>
    <div class="tabel_2">
      <table>
        <thead>
          <tr >
            <th>name</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody v-for="org in  organizations" :key="org.id">
          <td >{{ org.name }}</td>
          <td > {{ org.contact }}</td>
          <td >{{ org.address }}</td>
          <td>
            <button>Edit</button> |
            <button>Deleted</button>
          </td>
        </tbody>
      </table>
    </div>
  </div>
  
  </template>
  <script>
  export default {
    data() {
      return {
        
        loading: true,
        organizations: [],
        fields: [
          { key: "id", label: "ID" },
          { key: "name", label: "Name" },
          { key: "imageUrl", label: "Image" },
          { key: "contact", label: "Contact" },
          { key: "address", label: "Address" },
          { key: "actions", label: "Actions" },        
        ],      
      };
    },
    mounted() {
      this.fetchOrganizations();
    },
    methods: {
      fetchOrganizations() {
        
        this.$axios.get('/organizations')
          .then(response => {
            this.organizations = response.data.map(org => ({
              id: org._id,
              name: org.name,
              imageUrl: org.imageUrl,
              contact: org.contact,
              address: org.address,
            }));
            console.log("organizations:", this.organizations);
            
          ;
          })
          .catch(error => {
            alert('Error fetching organizations: ' + error.message);
          });
      },
    }
  };
  </script>
  <style>
  
  
  </style>